/*import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import RutInput from './utils/ui/RutInput'; // Asegúrate de que la ruta sea correcta
import { isValidRut } from './utils/helper/ValidaRUT';

const Login: React.FC<{ onLogin: (user: { id: number; name: string }) => void }> = ({ onLogin }) => {
    const [doc, setDoc] = useState<string>(''); // El RUT ingresado
    const [passwd, setPassword] = useState<string>(''); // La contraseña ingresada
    const [error, setError] = useState<string>(''); // Mensaje de error
    const navigate = useNavigate();

    // Limpia el RUT para la validación interna sin eliminar el guion
    const cleanRut = (rut: string) => {
        return rut.replace(/[^0-9kK-]/g, ''); // Limpia solo caracteres no numéricos y mantiene el guion
    };

    // Maneja los cambios en el RUT
    const handleRutChange = (value: string) => {
        setDoc(value); // Actualiza el estado con el valor formateado
    };

    // Maneja la validación del RUT al perder el foco
    const handleRutBlur = () => {
        const cleanedRut = cleanRut(doc); // Limpia el RUT antes de validar
        if (cleanedRut.length > 0 && !isValidRut(cleanedRut)) {
            setError('RUT inválido');
        } else {
            setError(''); // Resetea el error si el RUT es válido
        }
    };

    // Maneja el envío del formulario
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const cleanedRut = cleanRut(doc); // Limpia el RUT antes de validar

        // Verifica si el RUT es válido antes de enviar el formulario
        if (!isValidRut(cleanedRut)) {
            setError('RUT inválido');
            return;
        }

        try {
            // Realiza la solicitud de inicio de sesión
            const resp = await axios.post(config.apiUrl + '/auth/login', { doc: cleanedRut, passwd }, { withCredentials: true });
            if (resp.data) {
                const user = resp.data.userJson;
                onLogin({
                    id: user.id,
                    name: `${user.usuNombres} ${user.usu1erApellido} ${user.usu2doApellido}`
                });
                navigate('/dashboardRegistradorCurricular'); // Redirige al dashboard
            } else {
                setError('Credenciales Inválidas'); // Maneja el error de credenciales inválidas
            }
        } catch (error) {
            setError('Error: Credenciales Inválidas'); // Maneja errores de la solicitud
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row mt-3 justify-content-center'>
                <div className="col-10 col-sm-6 col-md-4 col-lg-3">
                    <div className="card">
                        <div className="card-title text-center">
                            <h2>Iniciar sesión</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label className="form-label" style={{ width: '100%' }}>RUT:
                                        <RutInput
                                            value={doc}
                                            onChange={handleRutChange}
                                            onBlur={handleRutBlur}
                                            validateOnSubmit={true} // Valida al enviar
                                        />
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <label className="form-label" style={{ width: '100%' }}>Contraseña:
                                        <input
                                            type="password"
                                            value={passwd}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control"
                                        />
                                    </label>
                                </div>
                                {error && <div style={{ color: 'red' }}>{error}</div>}
                                <button type="submit" className="btn btn-primary mt-3 w-100">Iniciar sesión</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;*/

import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import RutInput from './utils/ui/RutInput'; // Asegúrate de que la ruta sea correcta
import { isValidRut } from './utils/helper/ValidaRUT';

const Login: React.FC<{ onLogin: (user: { id: number; name: string }) => void }> = ({ onLogin }) => {
    const [doc, setDoc] = useState<string>(''); // El RUT ingresado
    const [passwd, setPassword] = useState<string>(''); // La contraseña ingresada
    const [error, setError] = useState<string>(''); // Mensaje de error
    const [showRutError, setShowRutError] = useState(false);
    const navigate = useNavigate();

    const handleBlur = () => {
        // Validar el RUT al perder el foco
        if (doc && !isValidRut(doc.replace(/[^0-9kK]/g, ''))) {
            setShowRutError(true);
        } else {
            setShowRutError(false);
        }
    };

    // Maneja el envío del formulario
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const cleanedDoc = doc.replace(/[^0-9kK]/g, '');

        if (doc && !isValidRut(cleanedDoc)) {
            setShowRutError(true); // Mostrar error si el RUT es inválido
            return; // No realizar la búsqueda
        }

        setShowRutError(false); // No hay error en el RUT, continuar con la búsqueda

        try {
            // Realiza la solicitud de inicio de sesión
            const resp = await axios.post(config.apiUrl + '/auth/login', { doc, passwd }, { withCredentials: true });
            if (resp.data) {
                const user = resp.data.userJson;
                onLogin({
                    id: user.id,
                    name: `${user.usuNombres} ${user.usu1erApellido} ${user.usu2doApellido}`
                });
                navigate('/dashboardRegistradorCurricular'); // Redirige al dashboard
            }
        } catch (error: unknown) {

            if (axios.isAxiosError(error)) {
                // Si es un AxiosError, maneja la respuesta y el estado
                if (error.response) {
                    if (error.response.status === 401) {
                        setError('Credenciales Inválidas');
                    } else {
                        setError(`Error: ${error.response.status}`);
                    }
                } else if (error.request) {
                    // La solicitud fue realizada pero no se recibió respuesta
                    setError('Error: No se recibió respuesta del servidor');
                } else {
                    // Problema al configurar la solicitud
                    setError('Error: Configuración de la solicitud fallida');
                }
            } else {
                // Si no es un AxiosError, maneja el error general
                setError('Error: Se produjo un error inesperado');
            }
        }
    };

    return (
        <div className='container-fluid'>
            <div className='row mt-3 justify-content-center'>
                <div className="col-10 col-sm-6 col-md-4 col-lg-3">
                    <div className="card">
                        <div className="card-title text-center">
                            <h2>Iniciar sesión</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label className="form-label" style={{ width: '100%' }}>RUT:
                                        <RutInput
                                            value={doc}
                                            onChange={setDoc}
                                            onBlur={handleBlur}
                                            validateOnSubmit={showRutError} // Valida al enviar
                                        />
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <label className="form-label" style={{ width: '100%' }}>Contraseña:
                                        <input
                                            type="password"
                                            value={passwd}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control"
                                        />
                                    </label>
                                </div>
                                {error && <div style={{ color: 'red' }}>{error}</div>}
                                <button type="submit" className="btn btn-primary mt-3 w-100">Iniciar sesión</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;

