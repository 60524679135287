//AceptaIngresoMinor.tsx

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { HeaderAlumno } from "../utils/ui/HeaderAlumno";
import { WindowsModal } from "../utils/ui/WindowsModal";
import { useModal } from "../utils/hook/useModal";

interface Props {
    alumno: { doc: string; name: string };
    aluCar: {
        carrera: string;
        codCar: number;
        agnoIng: number;
        semIng: number;
    };
    logro: { logro: string };
}

const AceptaIngresoMinor: React.FC<Props> = ({ alumno, aluCar, logro }) => {

    const { id, codCar, agnoIng, semIng, codMen, codPlan, correl } = useParams<{
        id: string;
        codCar: string;
        agnoIng: string;
        semIng: string;
        codMen: string;
        codPlan: string;
        correl: string;
    }>();
    const [, setConditionMet] = useState<number>();

    const defaultDate = new Date().toISOString().slice(0, 10);

    const [formData, setFormData] = useState({
        agno: 0,
        sem: 0,
        fecha: defaultDate,
        resolucion: 0
    });

    const { showModal, mensajeApi, handleShow, handleGoBack, setMensaje } = useModal();

    useEffect(() => {
        async function fetchCondition() {
            try {
                const response = await axios.get(
                    `${config.apiUrl}/api/expediente-logro/puedeTramitar`,
                    {
                        params: {
                            id,
                            codCar,
                            agnoIng,
                            semIng,
                            codMen,
                            codPlan,
                            correl,
                        },
                        withCredentials: true,
                    }
                );

                setConditionMet(response.data);
            } catch (error) {
                console.error("Error fetching condition:", error);
                setConditionMet(0);
            }
        }

        fetchCondition();
    }, [id, codCar, agnoIng, semIng, codMen, codPlan, correl]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${config.apiUrl}/api/expediente-logro/aceptaMinor`,
                {
                    id,
                    codCar,
                    agnoIng,
                    semIng,
                    codMen,
                    codPlan,
                    correl,
                    ...formData, // Añadimos los datos del formulario aquí
                },
                {
                    withCredentials: true,
                }
            );
            setMensaje(response.data.mensaje);
            handleShow();

        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <h2>Acepta Ingreso Minor</h2>
            <HeaderAlumno doc={alumno.doc} name={alumno.name} additionalData={[`${aluCar.carrera} - ${aluCar.codCar} - ${aluCar.agnoIng}/${aluCar.semIng}`, logro.logro]}></HeaderAlumno>

            <form onSubmit={handleSubmit}>
                <div className="row mt-5">
                    <label
                        htmlFor="agno"
                        className="form-label col-12 col-md-4 col-lg-2"
                    >
                        Ingreso (ej: 2020/1)
                    </label>
                    <div className="col-6 col-md-3 col-lg-2">
                        <input
                            type="number"
                            className="form-control"
                            id="agno"
                            name="agno"
                            placeholder="YYYY"
                            min="2000"
                            max="2030"
                            value={formData.agno}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <input
                            type="number"
                            className="form-control"
                            id="sem"
                            name="sem"
                            min="1"
                            max="2"
                            placeholder="Sem"
                            value={formData.sem}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-12"><hr /></div>
                </div>

                <div className="row mt-3">
                    <label
                        htmlFor="resolucion"
                        className="form-label col-12 col-md-4 col-lg-2"
                    >
                        Resolución
                    </label>
                    <div className="col-12 col-md-5 col-lg-3">
                        <input
                            type="number"
                            className="form-control"
                            id="resolucion"
                            name="resolucion"
                            value={formData.resolucion}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-12"><hr /></div>
                </div>

                <div className="row mt-3">
                    <label
                        htmlFor="fecha"
                        className="form-label col-12 col-md-4 col-lg-2"
                    >
                        Fecha Resolución
                    </label>
                    <div className="col-12 col-md-5 col-lg-3">
                        <input
                            type="date"
                            className="form-control"
                            id="fecha"
                            name="fecha"
                            min="2000-01-01"
                            value={formData.fecha}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-12"><hr /></div>
                </div>

                <button type="submit" className="btn btn-primary">
                    Enviar
                </button>
            </form>
            <WindowsModal show={showModal} handleClose={handleGoBack} mensaje={mensajeApi}></WindowsModal>
        </>
    );
};

export default AceptaIngresoMinor;
