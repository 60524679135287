//DashboardRegistradorCurricular.tsx

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from './NavbarRegistradorCurricular';
import Menu from './MenuRegistradorCurricular';
import { useNavigate, Outlet } from 'react-router-dom';
//import { useSelector, useDispatch } from 'react-redux';
//import { RootState } from '../../redux/store';

interface Props {
    user: {
        id: number;
        name: string;
    };
}

const DashboardRegistradorCurricular: React.FC<Props> = ({ user }) => {
    //const DashboardRegistradorCurricular: React.FC = () => {

    //const dispatch = useDispatch();
    //const user = useSelector((state: RootState) => state.user);

    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const menuItems = [
        { id: '1', label: 'Opción 1...', onClick: () => navigate('/DashboardRegistradorCurricular/OptionContent', { state: { content: 'Contenido de la Opción 1' } }) },
        { id: '2', label: 'Malla', onClick: () => navigate('/DashboardRegistradorCurricular/AlumnoSearch/MallaCurricular') },
        { id: '3', label: 'Programas de Estudio', onClick: () => navigate('/DashboardRegistradorCurricular/AlumnoSearch/ProgramasSearch') },
        { id: '4', label: 'Titulación', onClick: () => navigate("/DashboardRegistradorCurricular/AlumnoSearch/LogrosSearch") },
    ];

    return (
        <div className="dashboard">
            <Navbar username={user.name} onLogout={handleLogout} />
            <Container fluid>
                <Row>
                    <Col xs={4} md={2} className="bg-light pt-2" style={{ height: "90vh" }}>
                        <Menu items={menuItems} />
                    </Col>
                    <Col xs={8} md={10} style={{
                        overflow: "scroll",
                        height: "90vh"
                    }}>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DashboardRegistradorCurricular;