import React from 'react';
//import './Footer.css';

function Footer() {
    return (
        <footer>
            <p></p>
        </footer>
    );
}

export default Footer;