//ValidaRUT.tsx


export const isValidRut = (rut: string): boolean => {
    rut = rut.replace(/\./g, '').replace(/-/g, '');

    // Obtener dígito verificador
    const digitoVerificador = rut.charAt(rut.length - 1).toUpperCase();

    // Calcular dígito verificador
    let suma = 0;
    let multiplo = 2;
    for (let i = rut.length - 2; i >= 0; i--) {
        const digito = parseInt(rut.charAt(i), 10) * multiplo;
        suma += digito;
        multiplo = multiplo === 7 ? 2 : multiplo + 1;
    }
    const dvCalculado = 11 - (suma % 11);
    const dv = dvCalculado === 11 ? '0' : dvCalculado === 10 ? 'K' : dvCalculado.toString();

    // Comparar dígito verificador
    return digitoVerificador === dv;
};
