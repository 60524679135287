import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import config from '../../config';
import '../../assets/css/malla.css'
import LoadingMessage from '../utils/ui/LoadingMessage';
import NotFound from '../utils/ui/NotFound';

interface Nodo {
  nivel: number;
  asig: number;
  nombre: string;
  xxx: string;
  reprobaciones: number;
  situacion: string;
  yyy: string;
  linea: number;
  requisitos: string;
  correl: number;
  tel_sct: string;
}

const MallaCurricular: React.FC = () => {

  const [datosMalla, setDatosMalla] = useState<Nodo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { id, codCar, agnoIng, semIng, codMen, codPlan } = useParams<{ id: string, codCar: string, agnoIng: string, semIng: string, codMen: string, codPlan: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/api/malla/getNodos`, {
          params: { id, codCar, agnoIng, semIng, codMen, codPlan },
          withCredentials: true,
        });
        const nodos = JSON.parse(response.data);
        setDatosMalla(nodos);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los nodos de la malla:', error);
      }
    };

    fetchData();
  }, [id, codCar, agnoIng, semIng, codMen, codPlan]);

  const getColorClass = (situacion: string) => {
    let colorClass = situacion;
    if (situacion === 'R' || situacion === 'A') {
      colorClass += ' texto-blanco';
    }
    return colorClass;
  };

  const renderizarNodos = () => {
    const nivelesMalla = Array.from(new Set(datosMalla.map(item => item.nivel)));
    nivelesMalla.sort((a, b) => a - b);

    const filasMalla = Array.from(new Set(datosMalla.map(item => item.linea)));
    filasMalla.sort((a, b) => a - b);

    return (
      <div style={{ width: "1900px", overflow: "scroll" }}>
        <div className="contenedor-malla">
          <div className="fila-cabecera">
            {nivelesMalla.map(nivel => (
              <div key={`nivel-${nivel}`} className="nivel-cabecera">
                Nivel {nivel}
              </div>
            ))}
          </div>
          {filasMalla.map(fila => (
            <div key={`fila-${fila}`} className="fila-malla">
              {nivelesMalla.map(nivel => {
                const nodo = datosMalla.find(item => item.nivel === nivel && item.linea === fila);
                if (nodo) {
                  const colorClass = getColorClass(nodo.situacion);
                  return (

                    <div key={`${nodo.nivel}-${nodo.linea}`} className={`rectangulo-malla rectangulo-malla-${colorClass}`}>
                      <div className="segmento-superior">
                        <div className="label-superior">
                          <div className="numero-correlativo">{nodo.correl}</div>
                          <div className="codigo-asignatura">{nodo.asig}</div>
                        </div>
                      </div>
                      <div className="segmento-inferior">
                        <div className={`area-central area-central-${colorClass}`}>{nodo.nombre}</div>
                        <div className={`label-requisitos label-requisitos-${colorClass}`}>Req:{nodo.requisitos}</div>
                        <div className="label-inferior">
                          <div className={`creditos creditos-${colorClass}`}>{nodo.tel_sct}</div>
                          {nodo.reprobaciones > 0 && <div className="reprobaciones-rojo">{nodo.reprobaciones}</div>}
                        </div>
                      </div>
                    </div>

                  );
                } else {
                  return <div key={`empty-${nivel}-${fila}`} className="rectangulo-malla" style={{ visibility: 'hidden' }}></div>;
                }
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingMessage />
      ) : (
        datosMalla.length === 0 ? (
          <NotFound />
        ) : (
          renderizarNodos()
        )
      )}
    </div>
  );
}

export default MallaCurricular;