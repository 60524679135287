
//App.tsx

import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import DashboardRegistradorCurricular from './components/registroCurricular/DashboardRegistradorCurricular';
import AlumnoSearch from './components/consultaAlumno/AlumnoSearch';
import AluCarSearch from './components/consultaAlumno/AluCarSearch';
import LogrosSearch from './components/registroCurricular/LogrosSearch';
import MallaCurricular from './components/consultaAlumno/MallaCurricular';
import ProgramasSearch from './components/registroCurricular/ProgramasSearch';
import Titulacion from './components/registroCurricular/Titulacion';
import AceptaIngresoMinor from './components/registroCurricular/AceptaIngresoMinor';
import Expediente from './components/registroCurricular/Expediente';
import NavBar from './components/NavBar.js';
import Footer from './components/Footer.js';
import OptionContent from './components/OptionContent';

function App() {

  const [user, setUser] = useState<{ id: number; name: string } | null>(null);
  const [alumno, setAlumno] = useState<{ doc: string; name: string } | null>(null);
  const [aluCar, setAluCar] = useState<{ codCar: number; agnoIng: number; semIng: number; carrera: string } | null>(null);
  const [logro, setLogro] = useState<{ logro: string } | null>(null);

  return (
    <BrowserRouter>
      <div className="app-container">
        <NavBar />
        <Routes>
          <Route path="/" element={<Login onLogin={setUser} />} />
          <Route path="/login" element={<Login onLogin={setUser} />} />
          <Route path="/DashboardRegistradorCurricular" element={user ? <DashboardRegistradorCurricular user={user} /> : <Navigate to="/login" />}>
            <Route path="AlumnoSearch/:searchId" element={<AlumnoSearch onClick={setAlumno} />} />
            <Route
              path="AluCarSearch/:searchId/:id"
              element={alumno ? <AluCarSearch alumno={alumno} onSearch={setAluCar} /> : <Navigate to="/login" />}
            />
            <Route
              path="LogrosSearch/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan"
              element={alumno && aluCar ? <LogrosSearch alumno={alumno} aluCar={aluCar} onSearch={setLogro} /> : <Navigate to="/login" />}
            />
            <Route path="MallaCurricular/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan" element={<MallaCurricular />} />
            <Route path="ProgramasSearch/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan" element={<ProgramasSearch />} />
            <Route path="OptionContent" element={<OptionContent />} />
            <Route
              path="Titulacion/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan/:correl"
              element={alumno && aluCar && logro ? <Titulacion alumno={alumno} aluCar={aluCar} logro={logro} onSearch={setLogro} /> : <Navigate to="/login" />}
            />
            <Route
              path="AceptaIngresoMinor/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan/:correl"
              element={alumno && aluCar && logro ? <AceptaIngresoMinor alumno={alumno} aluCar={aluCar} logro={logro} /> : <Navigate to="/login" />}
            />
            <Route path="Expediente/:id/:codCar/:agnoIng/:semIng/:codMen/:codPlan/:correl" element={alumno && aluCar && logro ? <Expediente alumno={alumno} aluCar={aluCar} logro={logro} /> : <Navigate to="/login" />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >
  );
}

export default App;
