import React from 'react';
//import './NavBar.css';

function NavBar() {
    return (
        <div class="container-fluid">
            <div className='row bg-dark color-light'>
                <div class="col-12 text-center" style={{
                    color: "#fff"
                }}>
                    SAI - Universidad de Santiago de Chile
                </div>
            </div >
        </div>

    );
}

export default NavBar;