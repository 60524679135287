//WindowsModal.tsx

import React from "react";

// Define la interfaz para las props
interface WindowsModalProps {
    show: boolean; // Ejemplo de prop opcional
    handleClose: () => void;
    mensaje: string; // Array para los datos adicionales
}

export function WindowsModal({ show, handleClose, mensaje }: WindowsModalProps) {
    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex={-1} role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Mensaje</h5>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>{mensaje}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} >Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
