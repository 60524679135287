//date.ts

// Convierte fecha de formato dd-mm-yyyy a yyyy-mm-dd
export const convertToDateFormat = (dateStr: string): string => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
};

// Convierte a formato Date
export const convertFromDateFormat = (dateString: string | null): string => {
    if (!dateString) return ''; // Retorna una cadena vacía si el valor es null o undefined
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ''; // Retorna una cadena vacía si la fecha no es válida
    return date.toISOString().split('T')[0]; // Convierte a formato YYYY-MM-DD
};