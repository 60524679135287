//AluCarSearch.tsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import AluCar from '../types'
import config from '../../config';
import NotFound from '../utils/ui/NotFound';
import { HeaderAlumno } from '../utils/ui/HeaderAlumno';

interface Props {
    alumno: { doc: string; name: string },
    onSearch: (aluCar: { carrera: string; codCar: number, agnoIng: number, semIng: number }) => void;
}

const AluCarSearch: React.FC<Props> = ({ alumno, onSearch }) => {
    const { searchId, id } = useParams<{ searchId: string; id: string }>();
    const [searchResults, setSearchResults] = useState<AluCar[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSearchResults = async () => {
            const response = await axios.get(`${config.apiUrl}/api/alu-car/findByAlumno`,
                {
                    params: {
                        id
                    },
                    withCredentials: true,
                }
            );

            const aluCarData = JSON.parse(response.data);
            setSearchResults(aluCarData);
        };

        fetchSearchResults();
    }, [id]);

    const handleSearch = async (id: number, codCar: number, agnoIng: number, semIng: number, codMen: number, codPlan: number, carrera: string) => {
        onSearch({
            carrera, codCar, agnoIng, semIng
        });
        navigate(`/DashboardRegistradorCurricular/${searchId}/${id}/${codCar}/${agnoIng}/${semIng}/${codMen}/${codPlan}`);
    }

    return (
        <div>
            <h2>Carreras</h2>
            <HeaderAlumno doc={alumno.doc} name={alumno.name}></HeaderAlumno>

            <table className='table table-striped table-bordered table-hover mt-4'>
                <tbody>
                    {searchResults === null ? (
                        <NotFound />
                    ) : (
                        searchResults.map((result) => (
                            <tr key={result.id} onClick={() => handleSearch(result.id, result.codCar, result.agnoIng, result.semIng, result.codMen, result.codPlan, result.carrera)} style={{ cursor: 'pointer' }}>
                                <td>{result.id}</td><td> {result.agnoIng} {result.semIng} </td><td>{result.carrera}</td><td> {result.codCar}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AluCarSearch;