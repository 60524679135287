//Titulacion.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { HeaderAlumno } from '../utils/ui/HeaderAlumno';

interface Props {
    alumno: { doc: string; name: string },
    aluCar: { carrera: string; codCar: number, agnoIng: number, semIng: number },
    logro: { logro: string };
    onSearch: (logro: { logro: string }) => void;
}

const Titulacion: React.FC<Props> = ({ alumno, aluCar, logro, onSearch }) => {
    const { id, codCar, agnoIng, semIng, codMen, codPlan, correl } = useParams<{ id: string, codCar: string, agnoIng: string, semIng: string, codMen: string, codPlan: string, correl: string }>();
    const [conditionMet, setConditionMet] = useState<number>();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchCondition() {
            try {
                const response = await axios.get(`${config.apiUrl}/api/expediente-logro/puedeTramitar`, {
                    params: { id, codCar, agnoIng, semIng, codMen, codPlan, correl },
                    withCredentials: true
                });

                setConditionMet(response.data);

            } catch (error) {
                console.error('Error fetching condition:', error);
                setConditionMet(0);
            }
        }

        fetchCondition();
    }, [id, codCar, agnoIng, semIng, codMen, codPlan, correl]);


    const getFile = async (tipo: string) => {
        try {
            const response = await axios.get(`${config.apiUrl}/api/expediente-logro/get${tipo}`, {
                params: { id, codCar, agnoIng, semIng, codMen, codPlan, correl },
                withCredentials: true,
                responseType: 'blob' // Indicar que se espera una respuesta como Blob
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;

            // Extraer el nombre del archivo del encabezado 'Content-Disposition'
            const contentDisposition = response.headers['content-disposition'];
            let extractedFileName = `${tipo}_${id}.pdf`; // Nombre por defecto
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                    extractedFileName = fileNameMatch[1];
                }
            }

            link.download = extractedFileName;

            // Agregar el enlace al DOM, hacer click y limpiar después
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url); // Liberar el objeto URL creado

        } catch (error) {
            console.error('Error al obtener ' + tipo, error);
        }
    }

    const handleSearch = async (logro: string, path: string) => {
        onSearch({ logro });
        navigate(path);
    };

    return (
        <div>
            {conditionMet === 1 ? (
                <div>
                    <h2>Tramitación Logro</h2>
                    <HeaderAlumno doc={alumno.doc} name={alumno.name} additionalData={[`${aluCar.carrera} - ${aluCar.codCar} - ${aluCar.agnoIng}/${aluCar.semIng}`, logro.logro]}></HeaderAlumno>

                    <div className="d-grid gap-2 col-12 col-sm-8 col-md-4 col-lg-2 mt-4">
                        <button className="btn btn-primary" onClick={() => getFile('Caratula')}>Caratula</button>
                        <button className="btn btn-primary" onClick={() => getFile('Carta')}>Carta</button>
                        <button className="btn btn-primary" onClick={() => getFile('Finanzas')}>Ord. Despacho</button>
                        <button className="btn btn-primary" onClick={() => getFile('I2')}>I2</button>
                        <button className="btn btn-primary" onClick={() => handleSearch(logro.logro, `/DashboardRegistradorCurricular/AceptaIngresoMinor/${id}/${codCar}/${agnoIng}/${semIng}/${codMen}/${codPlan}/${correl}`)}>Ir a Ficha Acepta</button>
                        <button className="btn btn-primary" onClick={() => handleSearch(logro.logro, `/DashboardRegistradorCurricular/Expediente/${id}/${codCar}/${agnoIng}/${semIng}/${codMen}/${codPlan}/${correl}`)}>Ver Expediente</button>
                    </div>
                </div>
            ) : (
                <div>
                    <h2>Error</h2>
                    <p>Alumno no puede tramitar el logro debido a que no tiene registrada su resolución de aceptación</p>
                    <button className="btn btn-primary" onClick={() => handleSearch(logro.logro, `/DashboardRegistradorCurricular/AceptaIngresoMinor/${id}/${codCar}/${agnoIng}/${semIng}/${codMen}/${codPlan}/${correl}`)}>Ir a Ficha Acepta</button>
                </div>
            )}
        </div>
    );
};

export default Titulacion;