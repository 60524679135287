//ProgramasSearch.tsx

import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Asignatura from '../types'
import config from '../../config';

const ProgramasSearch: React.FC = () => {
    const { id, codCar, agnoIng, semIng } = useParams<{ id: string, codCar: string, agnoIng: string, semIng: string }>();
    const [searchResults, setSearchResults] = useState<Asignatura[]>([]);
    const [selectedAsignaturas, setSelectedAsignaturas] = useState<Set<string>>(new Set());

    useEffect(() => {
        const fetchSearchResults = async () => {
            const response = await axios.get(`${config.apiUrl}/api/programa-estudio/getAsignaturas`, {
                params: { id, codCar, agnoIng, semIng },
                withCredentials: true
            })

            const asignaturaData = JSON.parse(response.data);
            setSearchResults(asignaturaData);

        };
        fetchSearchResults();
    }, [id, codCar, agnoIng, semIng]);

    const handleCheckboxChange = (asiCod: number) => {
        const asiCodString = asiCod.toString();  // Convertir a string
        setSelectedAsignaturas(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(asiCodString)) {
                newSelected.delete(asiCodString);
            } else {
                newSelected.add(asiCodString);
            }
            return newSelected;
        });
    };


    const handleSubmit = async () => {
        try {
            const params = Array.from(selectedAsignaturas).map(asiCod => `asignaturas=${asiCod}`).join('&');
            const response = await axios.get(`${config.apiUrl}/api/programa-estudio/getProgramas?${params}`, {
                withCredentials: true,
                responseType: 'blob' // Asegúrate de especificar responseType como 'blob'
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;

            // Extraer el nombre del archivo del encabezado 'Content-Disposition'
            const contentDisposition = response.headers['content-disposition'];
            let extractedFileName = `programas-de-estudio.pdf`; // Nombre por defecto
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                    extractedFileName = fileNameMatch[1];
                }
            }

            link.download = extractedFileName;

            // Agregar el enlace al DOM, hacer click y limpiar después
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url); // Liberar el objeto URL creado

        } catch (error) {
            console.error(error);
            alert("Error al enviar las asignaturas seleccionadas.");
        }
    };


    return (
        <div className="form-check">
            <h2>Seleccione Programas</h2>
            <ul>
                {searchResults.map((result) => (
                    <li key={result.asiCod}>
                        <label className="form-check-label">
                            <input
                                type="checkbox"
                                checked={selectedAsignaturas.has(result.asiCod.toString())}
                                onChange={() => handleCheckboxChange(result.asiCod)}
                                className='form-check-input'
                            />
                            {result.asiCod} {result.asiNom}
                        </label>
                    </li>
                ))}
            </ul>
            <button onClick={handleSubmit} className="btn btn-primary">Enviar Selección</button>
        </div>
    );
};

export default ProgramasSearch;