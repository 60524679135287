import React from 'react';

const LoadingMessage: React.FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
            <div className="ml-2">Cargando datos...</div>
        </div>
    );
};

export default LoadingMessage;