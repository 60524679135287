import React from 'react';
import { useLocation } from 'react-router-dom';

const OptionContent: React.FC = () => {
    const location = useLocation();

    return (
        <div>
            <h2>Área de Trabajo</h2>
            <p>{location.state?.content}</p>
        </div>
    );
};

export default OptionContent;