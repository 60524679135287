//Expediente.tsx

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { convertFromDateFormat } from "../utils/helper/date";
import { WindowsModal } from "../utils/ui/WindowsModal";
import { useModal } from "../utils/hook/useModal";
import { HeaderAlumno } from "../utils/ui/HeaderAlumno";

interface Props {
    alumno: { doc: string; name: string };
    aluCar: {
        carrera: string;
        codCar: number;
        agnoIng: number;
        semIng: number;
    };
    logro: { logro: string };
}

interface FormData {
    agnoLogroAcad: number | null;
    semLogroAcad: number | null;
    fecLogroAcad: string | null;
    agnoLogroAdm: number | null;
    semLogroAdm: number | null;
    fecLogroAdm: string | null;
    numResol: number | null;
    fecResol: string | null;
    numExp: number | null;
    fecExp: string | null;
    numOrd: number | null;
    fecOrd: string | null;
    codPago: string | null;
    docPago: string | null;
    genero: "M" | "F";
    estado: "GE" | "TR" | "RE";
    rol: string | null;
    nomina: string | null;
    codLogro: number;
    logro: string;
    resoluciones: string | null;
}

const Expediente: React.FC<Props> = ({ alumno, aluCar, logro }) => {
    const { id, codCar, agnoIng, semIng, codMen, codPlan, correl } = useParams<{
        id: string;
        codCar: string;
        agnoIng: string;
        semIng: string;
        codMen: string;
        codPlan: string;
        correl: string;
    }>();

    const [formData, setFormData] = useState<FormData>({
        agnoLogroAcad: null,
        semLogroAcad: null,
        fecLogroAcad: null,
        agnoLogroAdm: null,
        semLogroAdm: null,
        fecLogroAdm: null,
        numResol: null,
        fecResol: null,
        numExp: null,
        fecExp: null,
        numOrd: null,
        fecOrd: null,
        codPago: null,
        docPago: null,
        genero: "M",
        estado: "GE",
        rol: null,
        nomina: null,
        codLogro: 0,
        logro: "",
        resoluciones: null,
    });

    const { showModal, mensajeApi, handleShow, handleGoBack, setMensaje } = useModal();

    useEffect(() => {
        async function fetchExpediente() {
            try {
                const response = await axios.get(`${config.apiUrl}/api/expediente-logro/getExpediente`, {
                    params: {
                        id,
                        codCar,
                        agnoIng,
                        semIng,
                        codMen,
                        codPlan,
                        correl,
                    },
                    withCredentials: true,
                });

                const expedienteData = JSON.parse(response.data);

                setFormData({
                    ...expedienteData,
                    fecLogroAcad: convertFromDateFormat(expedienteData.fecLogroAcad),
                    fecLogroAdm: convertFromDateFormat(expedienteData.fecLogroAdm),
                    fecResol: convertFromDateFormat(expedienteData.fecResol),
                    fecExp: convertFromDateFormat(expedienteData.fecExp),
                    fecOrd: convertFromDateFormat(expedienteData.fecOrd),
                });
            } catch (error) {
                console.error("Error en fetchExpediente:", error);
            }
        }

        fetchExpediente();
    }, [id, codCar, agnoIng, semIng, codMen, codPlan, correl]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${config.apiUrl}/api/expediente-logro/updateExpediente`,
                {
                    id,
                    codCar,
                    agnoIng,
                    semIng,
                    codMen,
                    codPlan,
                    correl,
                    ...formData, // Añadimos los datos del formulario aquí
                },
                {
                    withCredentials: true,
                }
            );
            setMensaje(response.data.mensaje);
            handleShow();

        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getFormDataValue = (key: keyof FormData): string => {
        const value = formData ? formData[key] : null;
        return value !== null ? value.toString() : "";
    };

    return (
        <>
            <h2>Expediente</h2>
            <HeaderAlumno doc={alumno.doc} name={alumno.name} additionalData={[`${aluCar.carrera} - ${aluCar.codCar} - ${aluCar.agnoIng}/${aluCar.semIng}`, logro.logro]}></HeaderAlumno>
            <div className="">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-2">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4>Fecha Académica</h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <label
                                                        htmlFor="agnoLogroAcad"
                                                        className="form-label">
                                                        Año
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="agnoLogroAcad"
                                                        name="agnoLogroAcad"
                                                        placeholder="YYYY"
                                                        min="2000"
                                                        max="2030"
                                                        value={getFormDataValue("agnoLogroAcad")}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="semLogroAcad"
                                                        className="form-label">
                                                        Sem
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="semLogroAcad"
                                                        name="semLogroAcad"
                                                        placeholder="S"
                                                        min="1"
                                                        max="2"
                                                        value={getFormDataValue("semLogroAcad")}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <label
                                                htmlFor="fecLogroAcad"
                                                className="form-label">
                                                Fecha
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fecLogroAcad"
                                                name="fecLogroAcad"
                                                value={getFormDataValue("fecLogroAcad")}
                                                onChange={handleChange}
                                                min="2000-01-01"
                                                max="2029-12-31"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4>Fecha Administrativa</h4>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <label
                                                        htmlFor="agnoLogroAdm"
                                                        className="form-label">
                                                        Año
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="agnoLogroAdm"
                                                        name="agnoLogroAdm"
                                                        placeholder="YYYY"
                                                        min="2000"
                                                        max="2030"
                                                        value={getFormDataValue("agnoLogroAdm")}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="semLogroAdm"
                                                        className="form-label">
                                                        Sem
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="semLogroAdm"
                                                        name="semLogroAdm"
                                                        placeholder="S"
                                                        min="1"
                                                        max="2"
                                                        value={getFormDataValue("semLogroAdm")}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <label
                                                htmlFor="fecLogroAdm"
                                                className="form-label">
                                                Fecha
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fecLogroAdm"
                                                name="fecLogroAdm"
                                                value={getFormDataValue("fecLogroAdm")}
                                                onChange={handleChange}
                                                min="2000-01-01"
                                                max="2029-12-31"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <label
                                                htmlFor="estado"
                                                className="form-label">
                                                Estado
                                            </label>
                                            <select
                                                value={formData.estado}
                                                className="form-select"
                                                id="estado"
                                                name="estado"
                                                onChange={handleChange}>
                                                <option value="GE" disabled>Generado</option>
                                                <option value="TR">En Trámite</option>
                                                <option value="RE" disabled>Finalizado</option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-5 mb-2">
                                            <label
                                                htmlFor="nomina"
                                                className="form-label">
                                                Nómina
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nomina"
                                                name="nomina"
                                                value={getFormDataValue("nomina")}
                                                onChange={handleChange}
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4>Género</h4>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label
                                                htmlFor="genero"
                                                className="form-label">
                                                Género
                                            </label>
                                            <select
                                                value={formData.genero}
                                                className="form-select"
                                                id="genero"
                                                name="genero"
                                                onChange={handleChange}>
                                                <option value="M">Masculino</option>
                                                <option value="F">Femenino</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mb-2">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4>Despacho</h4>
                                        </div>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <label
                                                htmlFor="numOrd"
                                                className="form-label">
                                                N° Ordinario
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="numOrd"
                                                name="numOrd"
                                                value={getFormDataValue("numOrd")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <label
                                                htmlFor="fecOrd"
                                                className="form-label">
                                                Fecha
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fecOrd"
                                                name="fecOrd"
                                                value={getFormDataValue("fecOrd")}
                                                onChange={handleChange}
                                                min="2000-01-01"
                                                max="2029-12-31"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <h4>Títulos y Grados</h4>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="rol"
                                                className="form-label">
                                                Rol Usach
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="rol"
                                                name="rol"
                                                value={getFormDataValue("rol")}
                                                onChange={handleChange}
                                                disabled
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="numResol"
                                                className="form-label">
                                                N° Res.
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="numResol"
                                                name="numResol"
                                                value={getFormDataValue("numResol")}
                                                onChange={handleChange}
                                                disabled
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="fecResol"
                                                className="form-label">
                                                Fecha Res.
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fecResol"
                                                name="fecResol"
                                                value={getFormDataValue("fecResol")}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="numExp"
                                                className="form-label">
                                                N° Exp.
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="numExp"
                                                name="numExp"
                                                value={getFormDataValue("numExp")}
                                                onChange={handleChange}
                                                disabled
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="fecExp"
                                                className="form-label">
                                                Fecha Exp.
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="fecExp"
                                                name="fecExp"
                                                value={getFormDataValue("fecExp")}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="codPago"
                                                className="form-label">
                                                Tipo Pago
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="codPago"
                                                name="codPago"
                                                value={getFormDataValue("codPago")}
                                                onChange={handleChange}
                                                disabled
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                            <label
                                                htmlFor="docPago"
                                                className="form-label">
                                                N° Doc.
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="docPago"
                                                name="docPago"
                                                value={getFormDataValue("docPago")}
                                                onChange={handleChange}
                                                disabled
                                                style={{ maxWidth: "120px" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <button
                            type="submit"
                            className="btn btn-primary">
                            Enviar
                        </button>
                    </div>
                </form>

                <WindowsModal
                    show={showModal}
                    handleClose={handleGoBack}
                    mensaje={mensajeApi}></WindowsModal>
            </div>
        </>
    );
};

export default Expediente;
