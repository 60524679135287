//LogrosSearch.tsx

import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import AluCar from '../types'
import config from '../../config';
import { HeaderAlumno } from '../utils/ui/HeaderAlumno';

interface Props {
    alumno: { doc: string; name: string },
    aluCar: { carrera: string; codCar: number, agnoIng: number, semIng: number },
    onSearch: (logro: { logro: string }) => void;
}

const LogrosSearch: React.FC<Props> = ({ alumno, aluCar, onSearch }) => {
    const { id, codCar, agnoIng, semIng, codMen, codPlan } = useParams<{ id: string, codCar: string, agnoIng: string, semIng: string, codMen: string, codPlan: string }>();
    const [searchResults, setSearchResults] = useState<AluCar[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSearchResults = async () => {
            const response = await axios.get(`${config.apiUrl}/api/expediente-logro/getLogros`, {
                params: { id, codCar, agnoIng, semIng, codMen, codPlan },
                withCredentials: true
            })

            const aluCarData = JSON.parse(response.data);
            setSearchResults(aluCarData);

        };
        fetchSearchResults();
    }, [id, codCar, agnoIng, semIng, codMen, codPlan]);

    const handleLogros = (correl: number, logro: string) => {
        onSearch({
            logro
        });
        navigate(`/DashboardRegistradorCurricular/Titulacion/${id}/${codCar}/${agnoIng}/${semIng}/${codMen}/${codPlan}/${correl}`);
    }

    return (
        <div>
            <h2>Logros</h2>
            <HeaderAlumno doc={alumno.doc} name={alumno.name} additionalData={[`${aluCar.carrera} - ${aluCar.codCar} - ${aluCar.agnoIng}/${aluCar.semIng}`]}></HeaderAlumno>

            <table className='table table-striped table-bordered table-hover mt-4'>
                <thead>
                    <th>Logro</th>
                </thead>
                <tbody>
                    {searchResults.map((result) => (
                        <tr key={result.id} onClick={() => handleLogros(result.correl, result.logro)} style={{ cursor: 'pointer' }}>
                            <td>{result.logro}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LogrosSearch;