// AlumnoSearch.tsx

import React, { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import Alumno from '../types';
import config from '../../config';
import LoadingMessage from '../utils/ui/LoadingMessage';
import NotFound from '../utils/ui/NotFound';
import RutInput from '../utils/ui/RutInput';
import { isValidRut } from '../utils/helper/ValidaRUT';

interface Props {
  onClick: (alumno: { doc: string; name: string }) => void;
}

const AlumnoSearch: React.FC<Props> = ({ onClick }) => {
  const { searchId } = useParams<{ searchId: string }>();
  const [alumnos, setAlumnos] = useState<Alumno[]>([]);
  const [doc, setDoc] = useState<string>("");
  const [ap1, setAp1] = useState<string>("");
  const [ap2, setAp2] = useState<string>("");
  const [nombre, setNombre] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [showRutError, setShowRutError] = useState(false);

  const navigate = useNavigate();

  const handleClickAlumno = (id: number, doc: string, name: string) => {
    onClick({ doc, name });
    navigate(`/DashboardRegistradorCurricular/AluCarSearch/${searchId}/${id}`);
  };

  async function handleSearchAlumno() {
    setIsLoading(true);

    // Validar el RUT antes de realizar la búsqueda
    const cleanedDoc = doc.replace(/[^0-9kK]/g, '');
    if (doc && !isValidRut(cleanedDoc)) {
      setShowRutError(true); // Mostrar error si el RUT es inválido
      setIsLoading(false);
      return; // No realizar la búsqueda
    }

    setShowRutError(false); // No hay error en el RUT, continuar con la búsqueda

    try {
      let response: AxiosResponse;
      if (doc || ap1 || ap2 || nombre) {
        if (doc) {
          response = await axios.get<Alumno[]>(`${config.apiUrl}/api/alumno/findByDoc`, {
            params: { doc },
            withCredentials: true,
          });
        } else {
          response = await axios.get<Alumno[]>(`${config.apiUrl}/api/alumno/findByName`, {
            params: { ap1, ap2, nombre },
            withCredentials: true,
          });
        }

        const alumnosData = JSON.parse(response.data);
        setAlumnos(alumnosData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setHasSearched(true);
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearchAlumno();
    }
  };

  const handleBlur = () => {
    // Validar el RUT al perder el foco
    if (doc && !isValidRut(doc.replace(/[^0-9kK]/g, ''))) {
      setShowRutError(true);
    } else {
      setShowRutError(false);
    }
  };

  return (
    <div className="row g-3 mt-2">
      <div className="col-12 col-sm-3 col-md-2 col-xl">
        <RutInput
          value={doc}
          onChange={setDoc}
          onEnter={handleSearchAlumno} // Llamar a la búsqueda al presionar Enter
          onBlur={handleBlur}
          validateOnSubmit={showRutError} // Solo valida al enviar
        />
      </div>
      <div className="col-12 col-sm-3 col-md-2 col-xl">
        <input
          className="form-control"
          type="text"
          placeholder="1° Apellido"
          value={ap1}
          onChange={(e) => setAp1(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
      </div>
      <div className="col-12 col-sm-3 col-md-2 col-xl">
        <input
          className="form-control"
          type="text"
          placeholder="2° Apellido"
          value={ap2}
          onChange={(e) => setAp2(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
      </div>
      <div className="col-12 col-sm-3 col-md-2 col-xl">
        <input
          className="form-control"
          type="text"
          placeholder="Nombre"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-4 col-xl d-grid gap-2">
        <button className="btn btn-primary b" onClick={handleSearchAlumno} disabled={isLoading}>
          Búsqueda Alumnos
        </button>
      </div>

      {isLoading && <LoadingMessage />}

      <div>
        {hasSearched && !isLoading && (alumnos === null || alumnos.length === 0) && (
          <NotFound />
        )}

        {!isLoading && alumnos && alumnos.length > 0 && (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th hidden></th>
                <th>RUT</th>
                <th>1° Apellido</th>
                <th>2° Apellido</th>
                <th>Nombre</th>
              </tr>
            </thead>
            <tbody>
              {alumnos.map((alumno) => (
                <tr key={alumno.aluDoc} onClick={() => handleClickAlumno(alumno.aluId, alumno.aluDoc, alumno.alu1erApellido + " " + alumno.alu2doApellido + " " + alumno.aluNombre)} style={{ cursor: 'pointer' }}>
                  <td hidden>{alumno.aluId}</td>
                  <td style={{ textAlign: 'right' }}>{alumno.aluDoc}</td>
                  <td>{alumno.alu1erApellido}</td>
                  <td>{alumno.alu2doApellido || ""}</td>
                  <td>{alumno.aluNombre}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AlumnoSearch;
