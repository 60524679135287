import React from 'react';

// Define la interfaz para las props
interface HeaderAlumnoProps {
    doc?: string; // Ejemplo de prop opcional
    name?: string;
    additionalData?: string[]; // Array para los datos adicionales

}

export function HeaderAlumno({ doc, name, additionalData }: HeaderAlumnoProps) {
    return (
        <div className="card mb-4">
            <div className="card-body">
                <p style={{ marginBottom: "0px" }}>
                    <strong>{doc}</strong><br />
                    {name}
                </p>
                {/* Renderiza los datos adicionales si existen */}
                {additionalData && additionalData.length > 0 && (
                    <div>
                        {additionalData.map((data, index) => (
                            <div key={index}>
                                {data}
                                <br />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}