//MenuRegistradorCurricular.tsx

import React from 'react';

interface MenuItem {
    id: string;
    label: string;
    onClick: () => void;
}

interface MenuProps {
    items: MenuItem[];
}

const MenuRegistradorCurricular: React.FC<MenuProps> = ({ items }) => {
    return (
        <div className="list-group">
            {items.map((item) => (
                <button
                    key={item.id}
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={item.onClick}
                >
                    {item.label}
                </button>
            ))}
        </div>
    );
};

export default MenuRegistradorCurricular;