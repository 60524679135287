// useModal.tsx

import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export function useModal() {
    const [showModal, setShowModal] = useState(false);
    const [mensajeApi, setMensajeApi] = useState<string>('');

    const navigate = useNavigate();

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const setMensaje = (mensaje: string) => setMensajeApi(mensaje);
    const handleGoBack = () => { navigate(-1) }

    return {
        showModal,
        mensajeApi,
        handleShow,
        handleClose,
        handleGoBack,
        setMensaje,
    };
}
